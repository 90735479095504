import consumer from "./consumer"

const exportFilesChannel = consumer.subscriptions.create({ channel: "ExportFilesChannel", user_id: $("meta[name='user-id']").attr('content') }, {
    connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to the export channel!");
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("Disconnected from the export channel!");
    },

    received(response) {
        if (response.type == 'export_files') {
            console.log('received')
            window.location.href = "/admin/postmasters/gpm_domains/:report_id/download_export".replace(':report_id', response.data.report_id)
        }else if (response.type == 'error') {
            console.log('error')
            alert(response.data.message)
        }
    },
});

export default exportFilesChannel;