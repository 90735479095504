// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
let channels = require("channels")

require("packs/admin/jquery")
require("packs/admin/bootstrap")
require("packs/admin/fastclick")
require("packs/admin/popover")

require("packs/admin/nprogress")
require("packs/admin/Chart.min")
require("packs/admin/gauge")
require("packs/admin/bootstrap-progressbar")
require("packs/admin/icheck")
require("packs/admin/skycons")

require("packs/admin/custom")
require("packs/admin/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import consumer from "../channels/consumer"

import flatpickr from "flatpickr"
import select2 from "select2"
Rails.consumer = consumer
global.Rails = Rails;
